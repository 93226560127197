import { j as F } from "./jsx-runtime-D0AQ1Mn7.js";
import { I as d } from "./Icon-vWfhOtJ0.js";
import { c as n } from "./utils-CJ9afRe1.js";
const m = {
  DEFAULT: "ClientFolder",
  EMPTY: "FolderEmpty",
  ASSET: "FolderAsset",
  BUSINESS: "FolderCopy",
  PROJECT: "FolderSettings",
  COMPANY: "FolderCompany",
  TRUST: "FolderTrust",
  INDIVIDUAL: "FolderIndividual"
}, I = ({
  type: o = "DEFAULT",
  width: e = 24,
  height: r = 20,
  strokeWidth: l = 1.5,
  style: s,
  className: t = ""
}) => /* @__PURE__ */ F.jsx(
  d,
  {
    name: m[o],
    width: e,
    height: r,
    className: n(t, o === "DEFAULT" ? "text-yellow-60" : ""),
    style: s,
    strokeWidth: l
  }
);
export {
  I as F
};
