import { j as r } from "./jsx-runtime-D0AQ1Mn7.js";
import { _ as s, a as _, b as E } from "./index-CWsRuimy.js";
import * as f from "react";
import { forwardRef as d, createElement as l, useRef as u } from "react";
import { $ as z } from "./index-BFOaq7n_.js";
import { $ as N, a as F, b as H, c as M, d as V, e as k, f as I, g as v, h as L, i as q } from "./index-BftHgWg_.js";
import { b as B } from "./index-Bkga1U-V.js";
import { V as y } from "./index-PuJQeDsA.js";
import { b as R } from "./Button-CAmI0_H8.js";
import { c as i, g as G } from "./utils-CJ9afRe1.js";
const J = "AlertDialog", [K, he] = z(J, [
  N
]), n = N(), Q = (a) => {
  const { __scopeAlertDialog: e, ...t } = a, o = n(e);
  return /* @__PURE__ */ l(L, s({}, o, t, {
    modal: !0
  }));
}, U = /* @__PURE__ */ d((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ l(q, s({}, c, o, {
    ref: e
  }));
}), W = (a) => {
  const { __scopeAlertDialog: e, ...t } = a, o = n(e);
  return /* @__PURE__ */ l(H, s({}, o, t));
}, X = /* @__PURE__ */ d((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ l(F, s({}, c, o, {
    ref: e
  }));
}), j = "AlertDialogContent", [Y, Z] = K(j), ee = /* @__PURE__ */ d((a, e) => {
  const { __scopeAlertDialog: t, children: o, ...c } = a, p = n(t), b = u(null), S = _(e, b), A = u(null);
  return /* @__PURE__ */ l(M, {
    contentName: j,
    titleName: ae,
    docsSlug: "alert-dialog"
  }, /* @__PURE__ */ l(Y, {
    scope: t,
    cancelRef: A
  }, /* @__PURE__ */ l(V, s({
    role: "alertdialog"
  }, p, c, {
    ref: S,
    onOpenAutoFocus: B(c.onOpenAutoFocus, ($) => {
      var g;
      $.preventDefault(), (g = A.current) === null || g === void 0 || g.focus({
        preventScroll: !0
      });
    }),
    onPointerDownOutside: ($) => $.preventDefault(),
    onInteractOutside: ($) => $.preventDefault()
  }), /* @__PURE__ */ l(E, null, o), !1)));
}), ae = "AlertDialogTitle", te = /* @__PURE__ */ d((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ l(k, s({}, c, o, {
    ref: e
  }));
}), oe = /* @__PURE__ */ d((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ l(I, s({}, c, o, {
    ref: e
  }));
}), ce = /* @__PURE__ */ d((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ l(v, s({}, c, o, {
    ref: e
  }));
}), re = "AlertDialogCancel", le = /* @__PURE__ */ d((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, { cancelRef: c } = Z(re, t), p = n(t), b = _(e, c);
  return /* @__PURE__ */ l(v, s({}, p, o, {
    ref: b
  }));
}), se = Q, ne = U, ie = W, de = X, fe = ee, $e = ce, pe = le, be = te, ge = oe, xe = se, me = ne, h = ie, x = f.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  de,
  {
    className: i(
      "fixed inset-0 z-full-screen bg-black-primary/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      a
    ),
    ...e,
    ref: t
  }
));
x.displayName = "AlertDialogOverlay";
const C = f.forwardRef(({ className: a, ...e }, t) => {
  const o = G(e.children, "AlertDialogTitle");
  return /* @__PURE__ */ r.jsxs(h, { children: [
    /* @__PURE__ */ r.jsx(x, { className: "z-full-screen" }),
    /* @__PURE__ */ r.jsxs(
      fe,
      {
        ref: t,
        className: i(
          "fixed left-[50%] top-[50%] z-full-screen flex flex-col w-full sm:max-w-sm lg:max-w-lg h-screen sm:h-auto sm:max-h-[95%] translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
          a
        ),
        ...e,
        children: [
          e.children,
          /* @__PURE__ */ r.jsxs(y, { children: [
            !o && /* @__PURE__ */ r.jsx(m, { children: "Dialog" }),
            /* @__PURE__ */ r.jsx(D, { children: "Dialog description" })
          ] })
        ]
      }
    )
  ] });
});
C.displayName = "AlertDialogContent";
const w = ({
  className: a,
  ...e
}) => /* @__PURE__ */ r.jsx(
  "div",
  {
    className: i(
      "flex flex-col space-y-2 sm:text-left",
      a
    ),
    ...e
  }
);
w.displayName = "AlertDialogHeader";
const P = ({
  className: a,
  ...e
}) => /* @__PURE__ */ r.jsx(
  "div",
  {
    className: i(
      "flex flex-row justify-end space-x-2 px-3 py-2",
      a
    ),
    ...e
  }
);
P.displayName = "AlertDialogFooter";
const m = f.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  be,
  {
    ref: t,
    className: i("", a),
    ...e
  }
));
m.displayName = "AlertDialogTitle";
const D = f.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  ge,
  {
    ref: t,
    className: i("text-sm text-muted-foreground", a),
    ...e
  }
));
D.displayName = "AlertDialogDescription";
const T = f.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  $e,
  {
    ref: t,
    className: i(R(), a),
    ...e
  }
));
T.displayName = "AlertDialogAction";
const O = f.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  pe,
  {
    ref: t,
    className: i(
      R({ variant: "outline" }),
      a
    ),
    ...e
  }
));
O.displayName = "AlertDialogCancel";
const Ce = Object.assign(xe, {
  Portal: h,
  Overlay: x,
  Trigger: me,
  Content: C,
  Header: w,
  Footer: P,
  Title: m,
  Description: D,
  Action: T,
  Cancel: O,
  VisuallyHidden: y
});
export {
  Ce as A
};
