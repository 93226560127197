import { a as N, _ as v, $ as de } from "./index-CWsRuimy.js";
import { forwardRef as m, useContext as ue, createContext as le, useState as W, useEffect as C, createElement as d, useRef as D, useCallback as Q, Children as fe, Fragment as $e } from "react";
import { b as P, $ as L, a as pe } from "./index-Bkga1U-V.js";
import { $ as be, b as ve } from "./index-BFOaq7n_.js";
import { $ as S } from "./index-iYICMc2K.js";
import { $ as x, a as ge } from "./index-BEhJEO-h.js";
import { $ as me } from "./index-DYqfa_Rt.js";
import Ee from "react-dom";
import { $ as H } from "./index-CaW1c6EJ.js";
import { R as he, $ as De } from "./Combination-C3243Ojg.js";
import { h as Oe } from "./index-pK5bN1zh.js";
const K = "dismissableLayer.update", _e = "dismissableLayer.pointerDownOutside", ye = "dismissableLayer.focusOutside";
let V;
const Ce = /* @__PURE__ */ le({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), Pe = /* @__PURE__ */ m((e, n) => {
  var t;
  const { disableOutsidePointerEvents: o = !1, onEscapeKeyDown: c, onPointerDownOutside: s, onFocusOutside: a, onInteractOutside: r, onDismiss: l, ...g } = e, u = ue(Ce), [f, A] = W(null), p = (t = f == null ? void 0 : f.ownerDocument) !== null && t !== void 0 ? t : globalThis == null ? void 0 : globalThis.document, [, w] = W({}), i = N(
    n,
    ($) => A($)
  ), _ = Array.from(u.layers), [b] = [
    ...u.layersWithOutsidePointerEventsDisabled
  ].slice(-1), T = _.indexOf(b), E = f ? _.indexOf(f) : -1, h = u.layersWithOutsidePointerEventsDisabled.size > 0, I = E >= T, ie = xe(($) => {
    const R = $.target, G = [
      ...u.branches
    ].some(
      (M) => M.contains(R)
    );
    !I || G || (s == null || s($), r == null || r($), $.defaultPrevented || l == null || l());
  }, p), z = Te(($) => {
    const R = $.target;
    [
      ...u.branches
    ].some(
      (M) => M.contains(R)
    ) || (a == null || a($), r == null || r($), $.defaultPrevented || l == null || l());
  }, p);
  return me(($) => {
    E === u.layers.size - 1 && (c == null || c($), !$.defaultPrevented && l && ($.preventDefault(), l()));
  }, p), C(() => {
    if (f)
      return o && (u.layersWithOutsidePointerEventsDisabled.size === 0 && (V = p.body.style.pointerEvents, p.body.style.pointerEvents = "none"), u.layersWithOutsidePointerEventsDisabled.add(f)), u.layers.add(f), X(), () => {
        o && u.layersWithOutsidePointerEventsDisabled.size === 1 && (p.body.style.pointerEvents = V);
      };
  }, [
    f,
    p,
    o,
    u
  ]), C(() => () => {
    f && (u.layers.delete(f), u.layersWithOutsidePointerEventsDisabled.delete(f), X());
  }, [
    f,
    u
  ]), C(() => {
    const $ = () => w({});
    return document.addEventListener(K, $), () => document.removeEventListener(K, $);
  }, []), /* @__PURE__ */ d(x.div, v({}, g, {
    ref: i,
    style: {
      pointerEvents: h ? I ? "auto" : "none" : void 0,
      ...e.style
    },
    onFocusCapture: P(e.onFocusCapture, z.onFocusCapture),
    onBlurCapture: P(e.onBlurCapture, z.onBlurCapture),
    onPointerDownCapture: P(e.onPointerDownCapture, ie.onPointerDownCapture)
  }));
});
function xe(e, n = globalThis == null ? void 0 : globalThis.document) {
  const t = L(e), o = D(!1), c = D(() => {
  });
  return C(() => {
    const s = (r) => {
      if (r.target && !o.current) {
        let g = function() {
          ee(_e, t, l, {
            discrete: !0
          });
        };
        const l = {
          originalEvent: r
        };
        r.pointerType === "touch" ? (n.removeEventListener("click", c.current), c.current = g, n.addEventListener("click", c.current, {
          once: !0
        })) : g();
      } else
        n.removeEventListener("click", c.current);
      o.current = !1;
    }, a = window.setTimeout(() => {
      n.addEventListener("pointerdown", s);
    }, 0);
    return () => {
      window.clearTimeout(a), n.removeEventListener("pointerdown", s), n.removeEventListener("click", c.current);
    };
  }, [
    n,
    t
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => o.current = !0
  };
}
function Te(e, n = globalThis == null ? void 0 : globalThis.document) {
  const t = L(e), o = D(!1);
  return C(() => {
    const c = (s) => {
      s.target && !o.current && ee(ye, t, {
        originalEvent: s
      }, {
        discrete: !1
      });
    };
    return n.addEventListener("focusin", c), () => n.removeEventListener("focusin", c);
  }, [
    n,
    t
  ]), {
    onFocusCapture: () => o.current = !0,
    onBlurCapture: () => o.current = !1
  };
}
function X() {
  const e = new CustomEvent(K);
  document.dispatchEvent(e);
}
function ee(e, n, t, { discrete: o }) {
  const c = t.originalEvent.target, s = new CustomEvent(e, {
    bubbles: !1,
    cancelable: !0,
    detail: t
  });
  n && c.addEventListener(e, n, {
    once: !0
  }), o ? ge(c, s) : c.dispatchEvent(s);
}
const k = "focusScope.autoFocusOnMount", U = "focusScope.autoFocusOnUnmount", Y = {
  bubbles: !1,
  cancelable: !0
}, Fe = /* @__PURE__ */ m((e, n) => {
  const { loop: t = !1, trapped: o = !1, onMountAutoFocus: c, onUnmountAutoFocus: s, ...a } = e, [r, l] = W(null), g = L(c), u = L(s), f = D(null), A = N(
    n,
    (i) => l(i)
  ), p = D({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  C(() => {
    if (o) {
      let i = function(E) {
        if (p.paused || !r) return;
        const h = E.target;
        r.contains(h) ? f.current = h : y(f.current, {
          select: !0
        });
      }, _ = function(E) {
        if (p.paused || !r) return;
        const h = E.relatedTarget;
        h !== null && (r.contains(h) || y(f.current, {
          select: !0
        }));
      }, b = function(E) {
        if (document.activeElement === document.body)
          for (const I of E) I.removedNodes.length > 0 && y(r);
      };
      document.addEventListener("focusin", i), document.addEventListener("focusout", _);
      const T = new MutationObserver(b);
      return r && T.observe(r, {
        childList: !0,
        subtree: !0
      }), () => {
        document.removeEventListener("focusin", i), document.removeEventListener("focusout", _), T.disconnect();
      };
    }
  }, [
    o,
    r,
    p.paused
  ]), C(() => {
    if (r) {
      q.add(p);
      const i = document.activeElement;
      if (!r.contains(i)) {
        const b = new CustomEvent(k, Y);
        r.addEventListener(k, g), r.dispatchEvent(b), b.defaultPrevented || (Ie(we(te(r)), {
          select: !0
        }), document.activeElement === i && y(r));
      }
      return () => {
        r.removeEventListener(k, g), setTimeout(() => {
          const b = new CustomEvent(U, Y);
          r.addEventListener(U, u), r.dispatchEvent(b), b.defaultPrevented || y(i ?? document.body, {
            select: !0
          }), r.removeEventListener(U, u), q.remove(p);
        }, 0);
      };
    }
  }, [
    r,
    g,
    u,
    p
  ]);
  const w = Q((i) => {
    if (!t && !o || p.paused) return;
    const _ = i.key === "Tab" && !i.altKey && !i.ctrlKey && !i.metaKey, b = document.activeElement;
    if (_ && b) {
      const T = i.currentTarget, [E, h] = Ne(T);
      E && h ? !i.shiftKey && b === h ? (i.preventDefault(), t && y(E, {
        select: !0
      })) : i.shiftKey && b === E && (i.preventDefault(), t && y(h, {
        select: !0
      })) : b === T && i.preventDefault();
    }
  }, [
    t,
    o,
    p.paused
  ]);
  return /* @__PURE__ */ d(x.div, v({
    tabIndex: -1
  }, a, {
    ref: A,
    onKeyDown: w
  }));
});
function Ie(e, { select: n = !1 } = {}) {
  const t = document.activeElement;
  for (const o of e)
    if (y(o, {
      select: n
    }), document.activeElement !== t) return;
}
function Ne(e) {
  const n = te(e), t = Z(n, e), o = Z(n.reverse(), e);
  return [
    t,
    o
  ];
}
function te(e) {
  const n = [], t = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (o) => {
      const c = o.tagName === "INPUT" && o.type === "hidden";
      return o.disabled || o.hidden || c ? NodeFilter.FILTER_SKIP : o.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; t.nextNode(); ) n.push(t.currentNode);
  return n;
}
function Z(e, n) {
  for (const t of e)
    if (!Re(t, {
      upTo: n
    })) return t;
}
function Re(e, { upTo: n }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (n !== void 0 && e === n) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function Le(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function y(e, { select: n = !1 } = {}) {
  if (e && e.focus) {
    const t = document.activeElement;
    e.focus({
      preventScroll: !0
    }), e !== t && Le(e) && n && e.select();
  }
}
const q = Ae();
function Ae() {
  let e = [];
  return {
    add(n) {
      const t = e[0];
      n !== t && (t == null || t.pause()), e = J(e, n), e.unshift(n);
    },
    remove(n) {
      var t;
      e = J(e, n), (t = e[0]) === null || t === void 0 || t.resume();
    }
  };
}
function J(e, n) {
  const t = [
    ...e
  ], o = t.indexOf(n);
  return o !== -1 && t.splice(o, 1), t;
}
function we(e) {
  return e.filter(
    (n) => n.tagName !== "A"
  );
}
const Me = /* @__PURE__ */ m((e, n) => {
  var t;
  const { container: o = globalThis == null || (t = globalThis.document) === null || t === void 0 ? void 0 : t.body, ...c } = e;
  return o ? /* @__PURE__ */ Ee.createPortal(/* @__PURE__ */ d(x.div, v({}, c, {
    ref: n
  })), o) : null;
}), ne = "Dialog", [oe, lt] = be(ne), [Se, O] = oe(ne), ke = (e) => {
  const { __scopeDialog: n, children: t, open: o, defaultOpen: c, onOpenChange: s, modal: a = !0 } = e, r = D(null), l = D(null), [g = !1, u] = pe({
    prop: o,
    defaultProp: c,
    onChange: s
  });
  return /* @__PURE__ */ d(Se, {
    scope: n,
    triggerRef: r,
    contentRef: l,
    contentId: S(),
    titleId: S(),
    descriptionId: S(),
    open: g,
    onOpenChange: u,
    onOpenToggle: Q(
      () => u(
        (f) => !f
      ),
      [
        u
      ]
    ),
    modal: a
  }, t);
}, Ue = "DialogTrigger", We = /* @__PURE__ */ m((e, n) => {
  const { __scopeDialog: t, ...o } = e, c = O(Ue, t), s = N(n, c.triggerRef);
  return /* @__PURE__ */ d(x.button, v({
    type: "button",
    "aria-haspopup": "dialog",
    "aria-expanded": c.open,
    "aria-controls": c.contentId,
    "data-state": j(c.open)
  }, o, {
    ref: s,
    onClick: P(e.onClick, c.onOpenToggle)
  }));
}), ce = "DialogPortal", [Ke, se] = oe(ce, {
  forceMount: void 0
}), Be = (e) => {
  const { __scopeDialog: n, forceMount: t, children: o, container: c } = e, s = O(ce, n);
  return /* @__PURE__ */ d(Ke, {
    scope: n,
    forceMount: t
  }, fe.map(
    o,
    (a) => /* @__PURE__ */ d(H, {
      present: t || s.open
    }, /* @__PURE__ */ d(Me, {
      asChild: !0,
      container: c
    }, a))
  ));
}, B = "DialogOverlay", He = /* @__PURE__ */ m((e, n) => {
  const t = se(B, e.__scopeDialog), { forceMount: o = t.forceMount, ...c } = e, s = O(B, e.__scopeDialog);
  return s.modal ? /* @__PURE__ */ d(H, {
    present: o || s.open
  }, /* @__PURE__ */ d(je, v({}, c, {
    ref: n
  }))) : null;
}), je = /* @__PURE__ */ m((e, n) => {
  const { __scopeDialog: t, ...o } = e, c = O(B, t);
  return (
    // Make sure `Content` is scrollable even when it doesn't live inside `RemoveScroll`
    // ie. when `Overlay` and `Content` are siblings
    /* @__PURE__ */ d(he, {
      as: de,
      allowPinchZoom: !0,
      shards: [
        c.contentRef
      ]
    }, /* @__PURE__ */ d(x.div, v({
      "data-state": j(c.open)
    }, o, {
      ref: n,
      style: {
        pointerEvents: "auto",
        ...o.style
      }
    })))
  );
}), F = "DialogContent", ze = /* @__PURE__ */ m((e, n) => {
  const t = se(F, e.__scopeDialog), { forceMount: o = t.forceMount, ...c } = e, s = O(F, e.__scopeDialog);
  return /* @__PURE__ */ d(H, {
    present: o || s.open
  }, s.modal ? /* @__PURE__ */ d(Ge, v({}, c, {
    ref: n
  })) : /* @__PURE__ */ d(Ve, v({}, c, {
    ref: n
  })));
}), Ge = /* @__PURE__ */ m((e, n) => {
  const t = O(F, e.__scopeDialog), o = D(null), c = N(n, t.contentRef, o);
  return C(() => {
    const s = o.current;
    if (s) return Oe(s);
  }, []), /* @__PURE__ */ d(re, v({}, e, {
    ref: c,
    trapFocus: t.open,
    disableOutsidePointerEvents: !0,
    onCloseAutoFocus: P(e.onCloseAutoFocus, (s) => {
      var a;
      s.preventDefault(), (a = t.triggerRef.current) === null || a === void 0 || a.focus();
    }),
    onPointerDownOutside: P(e.onPointerDownOutside, (s) => {
      const a = s.detail.originalEvent, r = a.button === 0 && a.ctrlKey === !0;
      (a.button === 2 || r) && s.preventDefault();
    }),
    onFocusOutside: P(
      e.onFocusOutside,
      (s) => s.preventDefault()
    )
  }));
}), Ve = /* @__PURE__ */ m((e, n) => {
  const t = O(F, e.__scopeDialog), o = D(!1), c = D(!1);
  return /* @__PURE__ */ d(re, v({}, e, {
    ref: n,
    trapFocus: !1,
    disableOutsidePointerEvents: !1,
    onCloseAutoFocus: (s) => {
      var a;
      if ((a = e.onCloseAutoFocus) === null || a === void 0 || a.call(e, s), !s.defaultPrevented) {
        var r;
        o.current || (r = t.triggerRef.current) === null || r === void 0 || r.focus(), s.preventDefault();
      }
      o.current = !1, c.current = !1;
    },
    onInteractOutside: (s) => {
      var a, r;
      (a = e.onInteractOutside) === null || a === void 0 || a.call(e, s), s.defaultPrevented || (o.current = !0, s.detail.originalEvent.type === "pointerdown" && (c.current = !0));
      const l = s.target;
      ((r = t.triggerRef.current) === null || r === void 0 ? void 0 : r.contains(l)) && s.preventDefault(), s.detail.originalEvent.type === "focusin" && c.current && s.preventDefault();
    }
  }));
}), re = /* @__PURE__ */ m((e, n) => {
  const { __scopeDialog: t, trapFocus: o, onOpenAutoFocus: c, onCloseAutoFocus: s, ...a } = e, r = O(F, t), l = D(null), g = N(n, l);
  return De(), /* @__PURE__ */ d($e, null, /* @__PURE__ */ d(Fe, {
    asChild: !0,
    loop: !0,
    trapped: o,
    onMountAutoFocus: c,
    onUnmountAutoFocus: s
  }, /* @__PURE__ */ d(Pe, v({
    role: "dialog",
    id: r.contentId,
    "aria-describedby": r.descriptionId,
    "aria-labelledby": r.titleId,
    "data-state": j(r.open)
  }, a, {
    ref: g,
    onDismiss: () => r.onOpenChange(!1)
  }))), !1);
}), ae = "DialogTitle", Xe = /* @__PURE__ */ m((e, n) => {
  const { __scopeDialog: t, ...o } = e, c = O(ae, t);
  return /* @__PURE__ */ d(x.h2, v({
    id: c.titleId
  }, o, {
    ref: n
  }));
}), Ye = "DialogDescription", Ze = /* @__PURE__ */ m((e, n) => {
  const { __scopeDialog: t, ...o } = e, c = O(Ye, t);
  return /* @__PURE__ */ d(x.p, v({
    id: c.descriptionId
  }, o, {
    ref: n
  }));
}), qe = "DialogClose", Je = /* @__PURE__ */ m((e, n) => {
  const { __scopeDialog: t, ...o } = e, c = O(qe, t);
  return /* @__PURE__ */ d(x.button, v({
    type: "button"
  }, o, {
    ref: n,
    onClick: P(
      e.onClick,
      () => c.onOpenChange(!1)
    )
  }));
});
function j(e) {
  return e ? "open" : "closed";
}
const Qe = "DialogTitleWarning", [ft, $t] = ve(Qe, {
  contentName: F,
  titleName: ae,
  docsSlug: "dialog"
}), pt = ke, bt = We, vt = Be, gt = He, mt = ze, Et = Xe, ht = Ze, Dt = Je;
export {
  lt as $,
  gt as a,
  vt as b,
  ft as c,
  mt as d,
  Et as e,
  ht as f,
  Dt as g,
  pt as h,
  bt as i
};
