import { j as r } from "./jsx-runtime-D0AQ1Mn7.js";
import "./Accordion-DcmbANja.js";
import "./Avatar-al9EpjB_.js";
import "./Badge-QBVLNL8O.js";
import "./Button-CAmI0_H8.js";
import "./DropdownMenu-8pjW7QUn.js";
import "./Icon-vWfhOtJ0.js";
import "./Input-CyIITBNc.js";
import "./Label-C4UDFZ-F.js";
import { T as i } from "./Text-CwTWgi3Y.js";
import "./Tooltip-B25WPLZY.js";
import { c as e } from "./utils-CJ9afRe1.js";
import "./Stack-B4QZt_R0.js";
import "./Switch-DSz0C0fA.js";
import "./Toast-CLY2YdmW.js";
import "react";
import "./Toggle-D-drA526.js";
const v = (t) => /* @__PURE__ */ r.jsxs(
  "div",
  {
    className: e("w-full max-w-fit rounded-md bg-white p-6 gap-4 flex flex-col items-center justify-center", t.className),
    children: [
      /* @__PURE__ */ r.jsx(i, { children: t.title }),
      /* @__PURE__ */ r.jsx("div", { className: "w-6 h-6 aspect-square rounded-full z-0 border-2 border-blue-100 border-t-transparent animate-spin" }),
      /* @__PURE__ */ r.jsx(i, { variant: "secondary", size: "xs", children: t.hint })
    ]
  }
);
export {
  v as L
};
